import * as React from "react";
import { navigate } from 'gatsby';
import { useAuth0 } from '../../hooks/use-auth0';

const LoginPage = () => {
    const { authProvider } = useAuth0();

    React.useEffect(() => {
        async function login() {
            try {
                if (!authProvider.isAuthenticated()) {
                    await authProvider.login();
                } else {
                    navigate('/admin');
                }
            } catch (error) {
                console.error('Login failed:', error);
                navigate('/login');
            }
        }
        login();
    }, [authProvider]);

    return (
        <>
            <h1>Redirecting to Login</h1>
        </>
    );
};

export default LoginPage;
